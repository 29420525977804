import React from "react";

import { withRouter } from "react-router-dom";

import PaymentSuccess from "../components/PaymentSuccess";

import Person from "./Person";
import PaymentDetails from "../components/PaymentDetails";

const PAYMENT_TYPE_AMOUNT = "amount";
const PAYMENT_TYPE_PERCENTAGE = "percentage";
const PAYMENT_TYPES = [PAYMENT_TYPE_AMOUNT, PAYMENT_TYPE_PERCENTAGE];

export class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = this._getState(props);

    this.paymentMethods = React.createRef();
  }

  _getState(props) {
    let qr = props.qr;

    let selectedPaymentType = qr.amount_default_view ? "amount" : "percentage";
    if (qr.type !== "tip") {
      selectedPaymentType = "amount";
    }
    let selectedPaymentOption = this._getDefaultPaymentOption(
      qr,
      selectedPaymentType
    );
    return {
      qr,
      billAmount: 0,
      selectedPaymentType,
      selectedPaymentOption,
      feeAmount: 0,
      tipAmount: this._getTipAmount(selectedPaymentOption, 0),
      reverseFees: qr.reverse_fees_enabled,
      customer: {
        first_name: "",
        last_name: "",
      },
    };
  }

  _getTipAmount(paymentOption, billAmount) {
    if (!paymentOption) {
      return 0;
    }

    if (!paymentOption.percentage) {
      return paymentOption.value;
    }

    return Math.round(billAmount * (paymentOption.value / 100));
  }

  _getDefaultPaymentOption(qr, paymentType) {
    let isPercentage = paymentType === "percentage";

    let options = qr.payment_options.filter(
      (option) => option.percentage === isPercentage
    );

    let defaultOption = options.find((option) => option.default === true);
    // if there's no default option set the first one as default
    return defaultOption || options[0];
  }

  render() {
    let {
      qr,
      customer,
      tipAmount,
      feeAmount,
      isLoading,
      reverseFees,
      showPaymentSuccessModal,
    } = this.state;

    if (showPaymentSuccessModal) {
      return (
        <div className="card enter-tip flex-grow-1">
          <PaymentSuccess qr={qr} total={tipAmount + feeAmount} />
        </div>
      );
    }

    return (
      <>
        <div className="card enter-tip flex-grow-1">
          <Person
            qr={qr}
            feeAmount={feeAmount}
            onUpdated={(tipAmount, selectedPaymentOption) => {
              this.setState({ tipAmount, selectedPaymentOption });
            }}
          />

          <PaymentDetails
            qr={qr}
            tipAmount={tipAmount}
            feeAmount={feeAmount}
            reverseFees={reverseFees}
            customer={customer}
            isLoading={isLoading}
            onFeesUpdated={(data) => {
              this.setState({
                feeAmount: data.fee,
              });
            }}
            onReverseFeesToggled={(reverseFees) => {
              this.setState({ reverseFees });
            }}
            onUpdatedCustomer={(customer) => this.setState({ customer })}
            onPayment={() => this.setState({ showPaymentSuccessModal: true })}
          />
        </div>
      </>
    );
  }
}

Form.defaultProps = {
  collectAddress: false,
  allowPayment: true,
};

export default withRouter(Form);
