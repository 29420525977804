import React from "react";

import { withRouter } from "react-router-dom";

import UserInfo from "../components/UserInfo";
import SegmentedControl from "../components/SegmentedControl";

import Tip from "./Tip";
import Bill from "./Bill";
import PaymentOptions from "./PaymentOptions";

import General from "../../utils/General";

const PAYMENT_TYPE_AMOUNT = "amount";
const PAYMENT_TYPE_PERCENTAGE = "percentage";
const PAYMENT_TYPES = [PAYMENT_TYPE_AMOUNT, PAYMENT_TYPE_PERCENTAGE];

export class Person extends React.Component {
  constructor(props) {
    super(props);

    this.state = this._getState(props);

    this.paymentMethods = React.createRef();
  }

  componentDidMount() {
    this._updateFees();
  }

  _getState(props) {
    let qr = props.qr;
    let selectedPaymentType = qr.amount_default_view ? "amount" : "percentage";
    if (qr.type !== "tip") {
      selectedPaymentType = "amount";
    }
    let selectedPaymentOption = props.selectedPaymentOption;

    if (!selectedPaymentOption && this.props.setDefaultPaymentOption) {
      selectedPaymentOption = this._getDefaultPaymentOption(
        qr,
        selectedPaymentType
      );
    }

    let defaultAmount = props.payers?.[qr.id]?.tipAmount;

    return {
      qr,
      billAmount: 0,
      selectedPaymentType,
      selectedPaymentOption,
      feeAmount: this.props.feeAmount,
      tipAmount: defaultAmount || this._getTipAmount(selectedPaymentOption, 0),
      reverseFees: qr.reverse_fees_enabled,
      customer: {
        first_name: "",
        last_name: "",
      },
      payers: props?.payers,
      personSelected: props.personSelected,
    };
  }

  _getTipAmount(paymentOption, billAmount) {
    if (!paymentOption) {
      return 0;
    }

    if (!paymentOption.percentage) {
      return paymentOption.value;
    }

    return Math.round(billAmount * (paymentOption.value / 100));
  }

  _getDefaultPaymentOption(qr, paymentType) {
    let isPercentage = paymentType === "percentage";

    let options = qr.payment_options.filter(
      (option) => option.percentage === isPercentage
    );

    let defaultOption = options.find((option) => option.default === true);
    // if there's no default option set the first one as default
    return defaultOption || options[0];
  }

  _updateFees = General.debounce(
    () => {
      let { tipAmount, selectedPaymentOption } = this.state;

      this.props.onUpdated(tipAmount, selectedPaymentOption);
    },
    300,
    false
  );

  render() {
    let {
      qr,
      tipAmount,
      billAmount,
      feeAmount,
      reverseFees,
      selectedPaymentType,
      selectedPaymentOption,
      payers,
    } = this.state;

    return (
      <div className="person">
        <UserInfo
          qr={qr}
          showPoolName={this.props.showPoolName}
          poolName={this.props.poolName}
        />

        <div className={qr.type === "tip" ? "tab-border" : ""}>
          {qr.type === "tip" && (
            <>
              <SegmentedControl
                selectedSegment={selectedPaymentType}
                segments={PAYMENT_TYPES}
                onSegmentSelected={(selectedPaymentType) => {
                  selectedPaymentOption = this._getDefaultPaymentOption(
                    qr,
                    selectedPaymentType
                  );
                  this.setState(
                    {
                      selectedPaymentType,
                      selectedPaymentOption,
                      tipAmount: this._getTipAmount(
                        selectedPaymentOption,
                        billAmount
                      ),
                    },
                    () => this._updateFees()
                  );
                }}
              />
            </>
          )}

          <PaymentOptions
            qr={qr}
            showTipBox={this.props.showTipBox}
            payers={payers}
            paymentType={selectedPaymentType}
            paymentOption={selectedPaymentOption}
            onUpdated={(paymentOption) => {
              this.setState(
                {
                  selectedPaymentOption: paymentOption,
                  tipAmount: this._getTipAmount(paymentOption, billAmount),
                },
                () => this._updateFees()
              );
            }}
            onUpdatedTipAmount={(tipAmount) =>
              this.setState({ tipAmount }, () => this._updateFees())
            }
            renderHeader={() => (
              <Bill
                qr={qr}
                billAmount={billAmount}
                paymentType={selectedPaymentType}
                onUpdated={(billAmount) => {
                  this.setState(
                    {
                      billAmount,
                      tipAmount: this._getTipAmount(
                        selectedPaymentOption,
                        billAmount
                      ),
                    },
                    () => this._updateFees()
                  );
                }}
                onUpdatedCustomer={this.props.onUpdatedCustomer}
                onPayPressed={this.props.onPayPressed}
              />
            )}
          />
        </div>

        {(!this.props.showTipBox || !selectedPaymentOption) && (
          <Tip
            qr={qr}
            tipAmount={tipAmount}
            billAmount={billAmount}
            feeAmount={this.props.feeAmount}
            paymentType={selectedPaymentType}
            paymentOption={selectedPaymentOption}
            reverseFees={reverseFees}
            onUpdated={(tipAmount) =>
              this.setState({ tipAmount }, () => this._updateFees())
            }
          />
        )}
      </div>
    );
  }
}

Person.defaultProps = {
  collectAddress: false,
  allowPayment: true,
  setDefaultPaymentOption: true,
};

export default withRouter(Person);
