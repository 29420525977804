import React from "react";

import { CardElement } from "@stripe/react-stripe-js";

import PayButton from "../button/PayButton";
import LockInputAppendIcon from "../../components/LockInputAppendIcon";

import Name from "../../../utils/Name";
import Notify from "../../../utils/Notify";

let CARD_ELEMENT_STYLE = {
  base: {
    iconColor: "#666666",
    color: "#fff",
    fontSize: "16px",
    "::placeholder": {
      color: "#87BBFD",
    },
  },
  invalid: {
    iconColor: "#FFC7EE",
    color: "#FFC7EE",
  },
};

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      qr: props.qr,
      customer: props.customer,
      tipAmount: props.tipAmount,
      feeAmount: props.feeAmount,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _pay() {
    this._getPaymentMethod()
      .then((stripePaymentMethod) => {
        let paymentDetails = {
          payment_method_id: stripePaymentMethod.id,
        };
        this.props.onPayPressed(paymentDetails);
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  async _getPaymentMethod() {
    const customer = this.props.customer;

    if (!customer.first_name) {
      throw { message: "Please specify the cardholder name" };
    }

    const cardElement = this.props.elements.getElement(CardElement);

    let billing_details = {
      name: customer.first_name + " " + customer.last_name,
    };

    // todo add address in collect address enabled
    return this.props.stripe
      .createPaymentMethod({
        type: "card",
        billing_details,
        card: cardElement,
      })
      .then(({ paymentMethod, error }) => {
        if (paymentMethod == null) {
          throw { message: "Please check your card details and try again" };
        }
        return paymentMethod;
      });
  }

  render() {
    let { qr, tipAmount, feeAmount, customer } = this.state;
    let style = {
      base: {
        fontSize: "18px",
        color: "#9600FF",
        fontFamily: 'Poppins, Helvetica, "sans-serif"',
        "::placeholder": {
          color: "rgba(40, 26, 200, 0.35)",
          textTransform: "capitalize",
          fontSize: "18px",
          fontFamily: 'Fellix TRIAL, Helvetica, "sans-serif"',
        },
        ":-webkit-autofill": {
          color: "#fff",
        },
      },
      invalid: {
        color: "#fa6160",
      },
    };

    let name = customer.first_name || null;
    if (name && customer.last_name) {
      name += " " + customer.last_name;
    }

    return (
      <div>
        {qr.type !== "tip" && (
          <div className="form-group mb--20">
            <div className="input-group with-append">
              <input
                className="form-control h-auto border-0"
                type="text"
                placeholder="Email (Optional)"
                value={customer.email}
                onChange={(e) => {
                  customer.email = e.target.value;
                  this.props.onUpdatedCustomer(customer);
                }}
              />
              <LockInputAppendIcon />
            </div>
          </div>
        )}

        <div className="form-group mb--20">
          <div className="input-group with-append">
            <input
              className="form-control h-auto border-0"
              type="text"
              placeholder="Name on Card"
              defaultValue=""
              onChange={(e) => {
                let fullName = e.target.value;
                customer.first_name = Name.getFirstName(fullName);
                customer.last_name = Name.getLastName(fullName);
                this.props.onUpdatedCustomer(customer);
              }}
            />
            <LockInputAppendIcon />
          </div>
        </div>

        <div className="form-group react-stripe mb--20 text-left">
          <div className="input-group with-append">
            <CardElement
              stripe={this.props.stripe}
              options={{
                style,
                hideIcon: true,
                hidePostalCode: !this.props.collectAddress,
              }}
              className="form-control h-auto border-0 pt-2"
            />

            <div class="input-group-append input-group-pill">
              <span class="input-group-text input-group-payment-form">
                <span className="svg-icon svg-icon-white svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <mask fill="white">
                        <use xlinkHref="#path-1" />
                      </mask>
                      <g />
                      <path
                        d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </div>
        <PayButton
          qr={qr}
          feeAmount={feeAmount}
          tipAmount={tipAmount}
          onClick={() => this._pay()}
        />
      </div>
    );
  }
}

Card.defaultProps = {};
