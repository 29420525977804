import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Header from "./components/Header";

import Backend from "../utils/Backend";

import PaymentForm from "./payment/Form";

import GroupForm from "./components/GroupForm";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedSegment: "AMOUNT",
      customAmount: false,
    };
  }

  componentDidMount() {
    let code = this.props.match.params.code;
    this._load(code);
  }

  _load(code) {
    this.setState({ loading: true });
    Backend.getQr(code)
      .then((qr) => {
        this.setState({ qr, loading: false });
      })
      .catch((error) => {
        console.log("error", error);
        window.location = `/404?q=${code}`;
      });
  }

  _renderContent() {
    let { qr } = this.state;

    if (qr.qrs?.length > 0) {
      return <GroupForm qr={qr} />;
    }
    return <PaymentForm qr={qr} />;
  }

  render() {
    let { loading, qr } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className="main min-vh-100 d-flex flex-column overflow-x px-0 bg-primary">
        <Header qr={qr} />
        {this._renderContent()}
      </div>
    );
  }
}
