import React from "react";

import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Card from "./Card";
import DigitalWallet from "./DigitalWallet";

import PayButton from "../button/PayButton";
import CardPayButton from "../button/CardPayButton";

import Name from "../../../utils/Name";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import PaymentMethodUtils from "../../../utils/PaymentMethods";

let stripePromise = null;

let stripeConnecPromise = null;

export default class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);

    this.state = this._getState(props);

    this.card = React.createRef();
    this.digitalWallet = React.createRef();

    this.customer = React.createRef();

    stripePromise = loadStripe(window.Api.Keys.StripePublishableKey);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return { ...props };
  }

  getPaymentDetails(paymentMethod) {
    if (paymentMethod.type === "card") {
      return this.card.current.collectDetails();
    }

    if (paymentMethod.type === "sepa") {
      return this.sepa.current.collectDetails();
    }

    return null;
  }

  handleAction(response) {
    return this._validatePayment(response);
  }

  _validatePayment(response) {
    return new Promise((resolve, reject) => {
      this._handleStripeAction(response).then((result) => {
        if (result.error) {
          return reject(result.error);
        }
        return resolve({
          payment_intent_id: result.paymentIntent.id,
          payment_method_id: response.payment_method_id,
        });
      });
    });
  }

  async _handleStripeAction(response) {
    const stripeConnectAccount = await loadStripe(
      window.Api.Keys.StripePublishableKey
      // Destination charges are on platform account
      // ,
      // {
      //   stripeAccount: response.account_id,
      // }
    );

    if (response.recurring_payment) {
      return stripeConnectAccount.confirmCardPayment(response.client_secret);
    } else {
      return stripeConnectAccount.handleCardAction(response.client_secret);
    }
  }

  _canShowCardInput() {
    let { canRenderCard } = this.state;

    return canRenderCard;
  }

  _renderCard(qr, customer) {
    let { canRenderCard, feeAmount, tipAmount } = this.state;

    return (
      <>
        {!canRenderCard && (
          <div className="row mb--20 justify-content-center">
            <div className="col-auto">
              <a
                href="javascript:void(0)"
                className="text-decoration-none enter-card-details d-flex align-items-center"
                onClick={() => this.setState({ canRenderCard: true })}
              >
                <svg
                  width={32}
                  height={21}
                  viewBox="0 0 32 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.8 0H3.2C1.4352 0 0 1.17731 0 2.625V18.375C0 19.8227 1.4352 21 3.2 21H28.8C30.5648 21 32 19.8227 32 18.375V2.625C32 1.17731 30.5648 0 28.8 0ZM16.8 15.75C15.7391 15.75 14.7217 15.4043 13.9716 14.7889C13.2214 14.1736 12.8 13.339 12.8 12.4688C12.8 11.5985 13.2214 10.7639 13.9716 10.1486C14.7217 9.5332 15.7391 9.1875 16.8 9.1875C17.6683 9.18915 18.5117 9.42531 19.2 9.8595C18.2336 10.458 17.6 11.3991 17.6 12.4688C17.6 13.5384 18.2336 14.4795 19.2 15.078C18.5117 15.5122 17.6683 15.7484 16.8 15.75ZM23.2 15.75C22.1391 15.75 21.1217 15.4043 20.3716 14.7889C19.6214 14.1736 19.2 13.339 19.2 12.4688C19.2 11.5985 19.6214 10.7639 20.3716 10.1486C21.1217 9.5332 22.1391 9.1875 23.2 9.1875C24.2609 9.1875 25.2783 9.5332 26.0284 10.1486C26.7786 10.7639 27.2 11.5985 27.2 12.4688C27.2 13.339 26.7786 14.1736 26.0284 14.7889C25.2783 15.4043 24.2609 15.75 23.2 15.75Z"
                    fill="#9600FF"
                  />
                </svg>
                Enter Card Details
              </a>
            </div>
          </div>
        )}
        {canRenderCard && (
          <>
            <Card
              ref={this.card}
              stripe={this.stripe}
              elements={this.elements}
              qr={qr}
              feeAmount={feeAmount}
              tipAmount={tipAmount}
              customer={customer}
              onPayPressed={(paymentDetails) =>
                this.props.onPayPressed(paymentDetails)
              }
              onUpdatedCustomer={(customer) =>
                this.props.onUpdatedCustomer(customer)
              }
            />
          </>
        )}
      </>
    );
  }

  _renderDigitalWallet() {
    let { qr, tipAmount, feeAmount, customer } = this.state;

    return (
      <DigitalWallet
        ref={this.digitalWallet}
        stripe={this.stripe}
        elements={this.elements}
        qr={qr}
        tipAmount={tipAmount}
        feeAmount={feeAmount}
        onPayPressed={(paymentDetails, billingDetails) => {
          let fullName = billingDetails.name;
          if (!customer.first_name) {
            customer.first_name = Name.getFirstName(fullName);
            customer.last_name = Name.getLastName(fullName);
          }

          let email = billingDetails.email;
          customer.email = customer.email || email;

          this.props.onUpdatedCustomer(customer);
          this.props.onPayPressed(paymentDetails);
        }}
      />
    );
  }

  render() {
    const fonts = [
      {
        cssSrc: "https://fonts.googleapis.com/css2?family=Poppins&display=swap",
      },
    ];

    let { qr, customer } = this.state;

    if (!stripePromise) {
      return null;
    }

    return (
      <Elements stripe={stripePromise} fonts={fonts}>
        <ElementsConsumer fonts={fonts}>
          {({ stripe, elements }) => {
            this.stripe = stripe;
            this.elements = elements;

            return (
              <>
                {this._renderDigitalWallet(qr, customer)}
                {this._renderCard(qr, customer)}
              </>
            );
          }}
        </ElementsConsumer>
      </Elements>
    );
  }
}
