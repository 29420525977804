import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.QrCodes                    = window.Api.Base + "/qrs"
window.Api.Payments                   = window.Api.Base + "/payments"
window.Api.TransactionFees            = window.Api.Base + "/transaction-fees"

window.Api.Keys = {}

window.Api.Keys.StripePublishableKey  = env.REACT_APP_STRIPE_PUBLISHABLE_KEY
