import React from "react";

export default class SegmentedControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      segments: props.segments,
      selectedSegment: props.selectedSegment,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _isActive(segment) {
    let { selectedSegment } = this.state;
    if (selectedSegment == segment) {
      return "active";
    }
    return "";
  }

  _renderSegments() {
    let { segments, selectedSegment } = this.state;

    return segments.map((segment, index) => {
      return (
        <button
          className={"nav-link text-uppercase " + this._isActive(segment)}
          onClick={() => this.props.onSegmentSelected(segment)}
        >
          {segment}
        </button>
      );
    });
  }

  render() {
    return (
      <nav>
        <div
          className="nav nav-pills nav-fill bg-light rounded"
          id="nav-tab"
          role="tablist"
        >
          {this._renderSegments()}
        </div>
      </nav>
    );
  }
}

SegmentedControl.defaultProps = {
  segments: ["Segment 1", "Segment 2"],
};
