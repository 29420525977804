import React from "react";

import Currency from "../../utils/Currency";

export default class Bill extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qr: props.qr,
      billAmount: props.billAmount,
      paymentType: props.paymentType,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { qr, billAmount, paymentType } = this.state;

    if (paymentType !== "percentage") {
      return null;
    }

    // show empty space instead of 0 by default
    let value = billAmount / 100 || "";

    return (
      <div className="bill row mb--20 justify-content-center">
        <div className="col feild-tip bg-light rounded">
          <label>Bill: {qr.currency.symbol}</label>
          <input
            className="input"
            type="number"
            min="0"
            defaultValue={value}
            onChange={(e) => {
              billAmount = Math.abs(
                Math.round(parseFloat(e.target.value) * 100)
              );

              this.props.onUpdated(billAmount);
            }}
          />
        </div>
      </div>
    );
  }
}

Bill.defaultProps = {};
