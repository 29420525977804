import FetchHelper from "./FetchHelper";
import AuthManager from "./AuthManager";

export default class Backend {
  static getQr(code) {
    return FetchHelper.get(window.Api.QrCodes + "/" + code);
  }

  static getFees(qrCode, amount, reverseFees) {
    let url =
      window.Api.TransactionFees +
      "?qr_id=" +
      qrCode.id +
      "&tip=" +
      amount +
      "&reverse_fees=" +
      reverseFees;
    return FetchHelper.get(url);
  }

  static makePayment(
    qr,
    tip,
    fee,
    customer,
    paymentOption,
    paymentDetails,
    reverseFees,
    payers
  ) {
    let data = {
      tip,
      customer,
      ...paymentDetails,
      qr: qr.id,
      total_charge: tip + fee,
      reverse_fees: reverseFees,
      payment_option_id: paymentOption?.id || null,
    };

    if (payers) {
      data.splits = [];

      for (const [key, payer] of Object.entries(payers)) {
        data.splits.push({
          qr: key,
          amount: payer.tipAmount,
          payment_option_id: payer.paymentOption?.id,
        });
      }
    }

    return FetchHelper.post(window.Api.Payments, data);
  }
}
