import React from "react";

import { toast } from "react-toastify";

import Event from "../../utils/Event";

import { SHOW_BACK_BUTTON } from "../../constants/Events";

export default class UserInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    Event.emit(SHOW_BACK_BUTTON, true);
  }

  _renderImage(image) {
    return (
      <>
        <img src={image.thumbnail} alt="User Profile" />
      </>
    );
  }

  _renderInitials(initials) {
    return (
      <span className="h1 overflow-hidden bg-secondary d-flex justify-content-center align-items-center">
        {initials}
      </span>
    );
  }

  render() {
    let qr = this.props.qr;
    let pool = this.props.showPoolName;

    let client = qr.client;
    let initials = client
      ? client.user.first_name[0] + client.user.last_name[0]
      : qr.name[0];
    let regularQrImage = qr?.payment_portal_image || client?.avatar
    let image = pool ? qr?.payment_portal_image : regularQrImage
    let description = qr?.description;

    return (
      <div className="user text-primary">
        <div className="text">
          <p class="mb-2">{qr.name}</p>
          {pool && <small>{this.props.poolName}</small>}

          {description && <small>{description}</small>}
        </div>

        {image && this._renderImage(image)}

        {!image && this._renderInitials(initials)}

        <span className="bg">&nbsp;</span>
      </div>
    );
  }
}
