export default class Email {
  static getFirstName(fullName){
    if(!fullName){
      return null
    }

    return fullName.split(" ")[0]
  }

  static getLastName(fullName){
    if(!fullName){
      return null
    }

    let names = fullName.split(" ")
    if(names.length <= 1){
      return null
    }

    let lastName = ""
    for(var i = 1; i < names.length; i++){
      lastName += names[i] + " "
    }

    return lastName.slice(0, -1)
  }
}
