import React from "react";

import {
  isMobile,
  isIOS,
  isSafari,
  isChrome,
  isAndroid,
  isMacOS,
} from "react-device-detect";

import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

import General from "../../../utils/General";
import GooglePayButton from "../button/GooglePayButton";
import ApplePayButton from "../button/ApplePayButton";

export default class DigitalWalletButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qr: props.qr,
      tipAmount: props.tipAmount,
      feeAmount: props.feeAmount,
      loading: true,
    };
  }

  componentDidMount() {
    this._update(this.state.paymentPlan);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._update());
  }

  _update = General.debounce(
    () => {
      let { qr, tipAmount, feeAmount } = this.state;

      this.setState({
        canMakePayment: false,
        paymentRequest: null,
        loading: true,
      });

      const paymentRequest = this.props.stripe.paymentRequest({
        country: "IE",
        currency: qr.currency.code,
        total: {
          label: "Total",
          amount: tipAmount + feeAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        // full addrress is witheld by the browser so unusable
        // see: https://stripe.com/docs/js/payment_request/events/on_shipping_address_change
        requestShipping: false,
      });

      paymentRequest.on("paymentmethod", async (ev) => {
        this.props.onPayPressed(
          {
            payment_method_id: ev.paymentMethod.id,
          },
          ev.paymentMethod.billing_details
        );
        ev.complete("success");
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          this.setState({
            canMakePayment: true,
            loading: false,
            paymentRequest,
            applePay: result.applePay,
          });
        } else {
          this.setState({
            canMakePayment: false,
          });
        }
      });
    },
    300,
    false
  );

  render() {
    let { canMakePayment, paymentRequest, applePay, loading } = this.state;

    if (!canMakePayment || !paymentRequest) {
      return null;
    }

    if (applePay) {
      return (
        <ApplePayButton
          loading={loading}
          onClick={(e) => paymentRequest.show()}
        />
      );
    }

    if (isAndroid || isChrome) {
      return (
        <GooglePayButton
          loading={loading}
          onClick={(e) => paymentRequest.show()}
        />
      );
    }
    return (
      <PaymentRequestButtonElement
        options={{ paymentRequest }}
        className="digital-wallet-button"
      />
    );
  }
}
