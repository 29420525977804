import React from "react";

import TipCard from "./TipCard";

export default class PersonSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
    };
  }
  render() {
    let { searchTerm } = this.state;

    let qr = this.props?.qr;
    let client = qr.client;
    let initials = client
      ? client.user.first_name[0] + client.user.last_name[0]
      : qr.name[0];
    let image = qr.payment_portal_image;

    let filteredQrs = qr.qrs.filter((qr) => {
      if (searchTerm === "") {
        return true;
      }
      return qr.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return (
      <div className="person-selector">
        {/* Starts : User Info */}
        <div className="user text-primary">
          <div className="text">
            <p class="mb-2">{qr?.name}</p>
            <small className="fs-13 fellix-light lh-1">{qr?.description}</small>
          </div>

          {image && <img src={image.thumbnail} alt="Profile" />}
          {!image && (
            <span className="h1 overflow-hidden bg-secondary d-flex justify-content-center align-items-center">
              {initials}
            </span>
          )}
          <span className="bg">&nbsp;</span>
        </div>
        {/* End : User Info */}
        {/* Starts : Search bar */}
        <div className="search-bar border-secondary border">
          <input
            type="text"
            placeholder="Search"
            className="w-100 border-0"
            onChange={(e) => this.setState({ searchTerm: e.target.value })}
          />
          <span className="icon" />
        </div>
        {/* End : Search bar */}

        {/* Starts : Results */}
        <div className="results">
          <div className="row g-2">
            {filteredQrs.map((qrInfo, index) => {
              return (
                <>
                  <TipCard
                    qr={qr}
                    buttonTitle={this.props.buttonTitle}
                    qrInfo={qrInfo}
                    key={qrInfo.id}
                    index={index}
                    onClick={this.props.onSelected}
                  />
                </>
              );
            })}
          </div>
        </div>
        {/* End : Results */}
      </div>
    );
  }
}
