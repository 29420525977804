import React, { Component } from "react";
import Currency from "../../utils/Currency";
import General from "../../utils/General";

import Person from "../payment/Person";
import PaymentDetails from "./PaymentDetails";
import PaymentSuccess from "./PaymentSuccess";
import PersonSelector from "./PersonSelector";

import TipButton from "./TipButton";

import Event from "../../utils/Event";

import { SHOW_BACK_BUTTON } from "../../constants/Events";
import { HIDE_BACK_BUTTON } from "../../constants/Events";

export default class GroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qr: props.qr,
      payers: {},
      showPoolName: true,
      isLoading: false,
      customer: {
        first_name: "",
        last_name: "",
      },
      reverseFees: props.qr.reverse_fees_enabled,
      feeAmount: 0,
    };
  }

  componentDidMount() {
    Event.emit(SHOW_BACK_BUTTON, false);

    Event.on(HIDE_BACK_BUTTON, () => {
      this.setState({
        personSelected: null,
      });
    });
  }

  componentWillUnmount() {
    Event.off(HIDE_BACK_BUTTON);
  }

  _renderContent() {
    let {
      qr,
      personSelected,
      payers,
      showPoolName,
      customer,
      isLoading,
      showPaymentSuccessModal,
      reverseFees,
      feeAmount,
    } = this.state;

    let buttonTitle = qr.qrs?.map((individualQr, index) => {
      let individualQrType = qr.qrs[index].type;

      return individualQrType == "other"
        ? "Pay"
        : General.toTitleCase(individualQrType);
    });

    let tipAmount = Object.keys(payers).reduce((sum, payerKey) => {
      let payer = payers[payerKey];
      return sum + payer.tipAmount;
    }, 0);

    let namesWithTipAmount = Object.keys(payers).map((key, index) => {
      let payer = payers[key];

      let tipEqualsMoreThanZero = payer.tipAmount > 0 || payer.tipAmount === 0;

      let buttonBoxTitle =
        payer.qr.type === "other" ? "Pay" : General.toTitleCase(payer.qr.type);

      return (
        <div className="name">
          <span>{buttonBoxTitle}:</span>
          <span>{payer.qr.name}</span>
          {tipEqualsMoreThanZero &&
            Currency.format(qr.currency.symbol, payer.tipAmount)}
        </div>
      );
    });

    let payer = personSelected ? payers[personSelected.id] : null;

    if (showPaymentSuccessModal) {
      return (
        <div className="card enter-tip flex-grow-1">
          <PaymentSuccess qr={qr} total={tipAmount + feeAmount} />
        </div>
      );
    }

    return (
      <div className="card enter-tip flex-grow-1 group-form">
        {!personSelected ? (
          <PersonSelector
            qr={qr}
            buttonTitle={buttonTitle}
            onSelected={(personSelected) => {
              this.setState({ personSelected });
            }}
          />
        ) : (
          <>
            <Person
              qr={personSelected}
              personSelected={personSelected}
              payers={payers}
              showTipBox={true}
              feeAmount={feeAmount}
              selectedPaymentOption={payer?.paymentOption}
              setDefaultPaymentOption={payer == null}
              showPoolName={showPoolName}
              poolName={qr?.name}
              onUpdated={(tipAmount, paymentOption) => {
                payers[personSelected.id] = {
                  qr: personSelected,
                  tipAmount: tipAmount,
                  paymentOption: paymentOption,
                };

                this.setState({
                  payers,
                });
              }}
            />

            <TipButton
              title="another member"
              onClick={() => {
                Event.emit(SHOW_BACK_BUTTON, false);

                this.setState({ personSelected: null });
              }}
            />

            <div className="row g-3 bg-light rounded another-member my--20">
              <div className="col">{namesWithTipAmount}</div>
              <small style={{ fontSize: "14px" }}>
                Charge{" "}
                {Currency.format(qr.currency.symbol, Math.abs(feeAmount))}
              </small>
            </div>

            <PaymentDetails
              qr={qr}
              tipAmount={tipAmount}
              feeAmount={feeAmount}
              onFeesUpdated={(data) =>
                this.setState({
                  feeAmount: data.fee,
                })
              }
              reverseFees={reverseFees}
              payers={payers}
              customer={customer}
              isLoading={isLoading}
              onReverseFeesToggled={(reverseFees) => {
                this.setState({ reverseFees });
              }}
              onUpdatedCustomer={(customer) => this.setState({ customer })}
              onPayment={() => this.setState({ showPaymentSuccessModal: true })}
            />
          </>
        )}
      </div>
    );
  }

  render() {
    return this._renderContent();
  }
}
