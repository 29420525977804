import React from "react";

import { toast } from "react-toastify";

import Event from "../../utils/Event";

import { SHOW_BACK_BUTTON } from "../../constants/Events";
import { HIDE_BACK_BUTTON } from "../../constants/Events";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    Event.on(SHOW_BACK_BUTTON, (showBack) => {
      this.setState({
        showBack,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentWillUnmount() {
    Event.off(SHOW_BACK_BUTTON);
  }

  render() {
    return (
      <>
        <div className="header">
          <a
            className="logo text-decoration-none"
            onClick={() => {
              if (this.state.showBack) {
                Event.emit(HIDE_BACK_BUTTON);
                this.setState({ showBack: false });
              } else {
                window.open(window.General.WebsiteUrl);
              }
            }}
            target="_blank"
            rel="noreferrer"
          >
            {this.props.qr.qrs?.length > 0 && (
              <>
                {this.state.showBack && (
                  <svg
                    width="40px"
                    height="40px"
                    style={{
                      transform: "translateY(-0.2rem)",
                      cursor: "pointer",
                    }}
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 512 512"
                  >
                    <path
                      d="M352 128.4 319.7 96 160 256l159.7 160 32.3-32.4L224.7 256z"
                      fill="#9600ff"
                      className="fill-000000"
                    />
                  </svg>
                )}
              </>
            )}
            Xelda
          </a>

          <svg
            className="shape-01"
            width={340}
            height={340}
            viewBox="0 0 340 340"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="261.338"
              width="369.588"
              height={110}
              rx={55}
              transform="rotate(-45 0 261.338)"
              fill="#00FFB1"
            />
            <circle cx={77} cy="79.1315" r={60} fill="white" />
            <circle cx={260} cy="263.131" r={60} fill="white" />
          </svg>
          <div className="shape-02 bg-secondary">&nbsp;</div>
        </div>
      </>
    );
  }
}
