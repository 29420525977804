import React from "react";

import Currency from "../../utils/Currency";
import General from "../../utils/General";

export default class PaymentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qr: props.qr,
      paymentType: props.paymentType,
      paymentOption: props.paymentOption,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderOptions(percentages) {
    let { qr, paymentOption } = this.state;

    if (qr.quick_link) {
      let paymentOption = qr.payment_options[0];
      return null;
    }

    let paymentOptions = qr.payment_options.filter(
      (option) => option.percentage === percentages
    );

    return (
      <div className="row g-2 choose-amount justify-content-center options">
        {paymentOptions.map((option) => {
          let text = "";
          if (option.percentage) {
            text = `${option.value}%`;
          } else {
            text = Currency.format(qr.currency.symbol, option.value);
          }
          return (
            <div key={option.id} className="col-auto">
              <input
                type="radio"
                name={option.percentage ? "percentage" : "amount"}
                value={option.id}
                checked={paymentOption?.id === option.id}
                onChange={() => this.props.onUpdated(option)}
              />
              <div>{text}</div>
            </div>
          );
        })}
      </div>
    );
  }

  _renderCustomPaymentOption() {
    let { qr, paymentOption } = this.state;
    if (!qr.custom_payment_enabled) {
      return null;
    }

    return (
      <div className="row mt--20 choose-amount justify-content-center">
        <div className="col-auto">
          <div className="or text-end">or</div>
        </div>
        <div className="col-auto">
          <input
            type="radio"
            name="amount"
            value="custom"
            checked={paymentOption == null}
            onClick={(e) => {
              this.props.onUpdated(null);

              General.focusAndOpenKeyboard(
                document.getElementById("input-tip")
              );
            }}
          />

          <div className="custom">
            {qr.currency.symbol}&nbsp;&nbsp;&nbsp;Other amount
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { qr, paymentType } = this.state;

    let isPaymentTypeAmount = paymentType === "amount";
    let amountClassName = isPaymentTypeAmount ? "amount show active" : "";
    let percentageClassName = !isPaymentTypeAmount ? "percentage show active" : "";

    return (
      <>
        <div className="tab-content text-center mt--20" id="nav-tabContent">
          
          <div
            className={`tab-pane fade ${amountClassName}`}
            id="nav-amount"
            role="tabpanel"
            aria-labelledby="nav-amount-tab"
          >
            {this._renderOptions(false)}
            {this._renderCustomPaymentOption()}
          </div>

          <div
            className={`tab-pane fade ${percentageClassName}`}
            id="nav-percentage"
            role="tabpanel"
            aria-labelledby="nav-percentage-tab"
          >
            {this.props.renderHeader()}
            <div className="pb--20">
              {this._renderOptions(true)}
            </div>
          </div>

        </div>
      </>
    );
  }
}

PaymentOptions.defaultProps = {};
