import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";

import Backend from "../../utils/Backend";
import Currency from "../../utils/Currency";
import General from "../../utils/General";

import PaymentMethods from "../payment/stripe/PaymentMethods";
import ErrorModal from "./modal/ErrorModal";

import Event from "../../utils/Event";

import { SHOW_BACK_BUTTON } from "../../constants/Events";

export default class PaymentDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qr: props.qr,
      feeAmount: props.feeAmount,
      payers: props.payers,
      tipAmount: props.tipAmount,
      reverseFees: props.reverseFees,
      customer: props.customer,
      isLoading: props.isLoading,
      showPaymentSuccessModal: props.showPaymentSuccessModal,
      showErrorModal: props.showErrorModal,
      error: props.error,
    };

    this.paymentMethods = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    let refreshFees = nextProps.reverseFees != this.state.reverseFees;

    if (!refreshFees) {
      refreshFees = nextProps.tipAmount != this.state.tipAmount;
    }

    this.setState(nextProps, () => {
      if (refreshFees) {
        this._updateFees();
      }
    });
  }

  componentDidMount() {
    this._updateFees();
  }

  _updateFees = General.debounce(
    () => {
      let { qr, tipAmount, reverseFees } = this.state;

      Backend.getFees(qr, tipAmount, reverseFees)
        .then((data) => {
          this.props.onFeesUpdated(data);
          this.setState({
            feeAmount: data.fee,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    300,
    false
  );

  _pay(paymentDetails) {
    let {
      qr,
      feeAmount,
      tipAmount,
      customer,
      selectedPaymentOption,
      reverseFees,
      payers,
    } = this.state;

    tipAmount = tipAmount > 0 && tipAmount;

    this.setState({ isLoading: true });
    Backend.makePayment(
      qr,
      tipAmount,
      feeAmount,
      customer,
      selectedPaymentOption,
      paymentDetails,
      reverseFees,
      payers
    )
      .then((response) => {
        if (response.requires_action) {
          return this.paymentMethods.current
            .handleAction(response)
            .then((paymentDetails) => {
              return this._pay(paymentDetails);
            });
        }
        this.setState({
          isLoading: false,
        });

        Event.emit(SHOW_BACK_BUTTON, false);

        this.props.onPayment();
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
          showErrorModal: true,
        });
      });
  }

  render() {
    let {
      qr,
      feeAmount,
      tipAmount,
      reverseFees,
      customer,
      isLoading,
      showErrorModal,
      error,
    } = this.state;

    return (
      <>
        {qr.reverse_fees_enabled && qr.type != "other" && (
          <>
            <div
              className="form-check m-auto form-switch mt-2 mb-3"
              onClick={() => {
                this.props.onReverseFeesToggled(!reverseFees);
              }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                checked={reverseFees}
              />
              <label className="form-check-label">Agree to pay the fees</label>
            </div>
              <div className="total text-center mt-2 mb-3">
                Total:{" "}
                {tipAmount + feeAmount > 0 &&
                  Currency.format(
                    qr.currency.symbol,
                    Math.abs(tipAmount + feeAmount)
                  )}
              </div>
          </>
        )}

        <div className="mt--20">
          <PaymentMethods
            qr={qr}
            ref={this.paymentMethods}
            customer={customer}
            tipAmount={tipAmount}
            feeAmount={feeAmount}
            onUpdatedCustomer={() => {}}
            onPayPressed={(paymentDetails) => {
              if (!isLoading) {
                this._pay(paymentDetails);
              }
            }}
          />
        </div>

        <ErrorModal
          show={showErrorModal}
          qr={qr}
          error={error}
          onClose={() => this.setState({ showErrorModal: false })}
        />

        <LoadingOverlay
          active={isLoading}
          className={isLoading ? "payment-form-loading-overlay" : ""}
          spinner
          text={
            <p>
              Loading... <br /> please do not refresh
            </p>
          }
        />
      </>
    );
  }
}
