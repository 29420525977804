import React from "react";
import Modal from "react-bootstrap/Modal";

export default class ErrorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      company: props.company,
      error: props.error,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { show, company, error } = this.props;

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Body>
          <div
            class="swal2-icon swal2-error swal2-icon-show"
            style={{ display: "flex" }}
          >
            <span class="swal2-x-mark">
              <span class="swal2-x-mark-line-left"></span>
              <span class="swal2-x-mark-line-right"></span>
            </span>
          </div>
          <h1 className="text-center">Oh Snap!</h1>
          <p className="text-center">
            {error?.message || "An unexpected error occured"}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 text-center">
            <button
              type="button"
              className={`btn btn-secondary w-50`}
              onClick={() => this.props.onClose()}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ErrorModal.defaultProps = {
  modalClassName: "",
};
