import React from 'react'

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import DigitalWalletButton from "./DigitalWalletButton"

export default class DigitalWallet extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      qr: props.qr,
      tipAmount: props.tipAmount,
      feeAmount: props.feeAmount
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render(){
    let {
      qr,
      tipAmount,
      feeAmount
    } = this.state

    return (
       <DigitalWalletButton
        stripe={this.props.stripe}
        qr={qr}
        tipAmount={tipAmount}
        feeAmount={feeAmount}
        onPayPressed={(paymentDetails, billingDetails) => this.props.onPayPressed(paymentDetails, billingDetails)}
      />
    )
  }
}

DigitalWallet.defaultProps = {
  renderFooter: () => null,
  renderHeader: () => null
}
