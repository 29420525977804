import React from "react";

import Currency from "../../utils/Currency";

const FEE_TYPE_NO_REVERSE = "i won't pay the fees";
const FEE_TYPE_REVERSE = "i'll pay the fees";
const FEE_TYPES = [FEE_TYPE_NO_REVERSE, FEE_TYPE_REVERSE];

export default class Tip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qr: props.qr,
      tipAmount: props.tipAmount,
      billAmount: props.billAmount,
      feeAmount: props.feeAmount,
      paymentType: props.paymentType,
      paymentOption: props.paymentOption,
    };
    this.inputTip = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { qr, tipAmount, billAmount, feeAmount, paymentType, paymentOption } =
      this.state;

    //let isPercentage = paymentType === "percentage";

    let props = {};

    let newValue = Math.abs(tipAmount / 100);

    /*if (isPercentage) {
      props.value = Currency.format("", tipAmount);
    } else {
      props.defaultValue = tipAmount / 100;
    }*/

    let tipDonateOther =
      qr.type === "other" ? "Pay" : qr.type !== "tip" ? "Donate" : "Tip";

    let tipDonateOtherStyles =
      qr.type === "other"
        ? "pay-label"
        : qr.type !== "tip"
        ? "donate-label fs-20"
        : "tip-label";

    let value = "";
    if (paymentType === "amount" && newValue > 0) {
      if (newValue > 0) {
        value = newValue;
      }
    } else if (paymentType !== "amount") {
      value = tipAmount / 100;
    }

    let currencySymbol = qr.currency.symbol;

    return (
      <>
        <div className="mt--20">
          <div className="col feild-tip bg-light rounded mb--10">
            <label className={`${tipDonateOtherStyles}`}>
              {tipDonateOther}: {currencySymbol}
            </label>
            <input
              id="input-tip"
              ref={this.inputTip}
              className="input input-tip"
              {...props}
              type="number"
              value={value}
              min="0"
              readOnly={paymentOption !== null}
              onChange={(e) => {
                tipAmount = Math.round(parseFloat(e.target.value) * 100);
                this.props.onUpdated(Math.abs(tipAmount));
              }}
            />
            {feeAmount > 0 && (
              <small>
                Charge {Currency.format(currencySymbol, Math.abs(feeAmount))}
              </small>
            )}
          </div>
        </div>
      </>
    );
  }
}

Tip.defaultProps = {};
