import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.General = {};

window.General.WebsiteUrl           = env.REACT_APP_WEBSITE_URL
window.General.WebsiteDomain        = env.REACT_APP_WEBSITE_DOMAIN

window.General.GooglePlayStoreUrl   =   env.REACT_APP_GOOGLE_PLAY_STORE_URL
window.General.AppleAppStoreUrl     =   env.REACT_APP_APPLE_APP_STORE_URL
