import React, { Component } from "react";

import $ from "jquery";

export default class TipCard extends Component {
  componentDidMount() {
    try {
      setTimeout(function () {
        $(".tip-card .title .name").each(function () {
          var height = 18;
          if ($(this).outerHeight() > height) {
            for (let i = 0; $(this).outerHeight() > height; i++) {
              var fontsize = $(this).css("font-size");
              $(this).css("font-size", parseFloat(fontsize) - 1);
            }
          }
        });
      }, 200);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    let qrInfo = this.props.qrInfo;

    let buttonTitle = this.props.buttonTitle;

    return (
      <div
        className="col-4 tip-card"
        onClick={() => this.props.onClick(qrInfo)}
      >
        <div className="title bg-secondary fs-14 fellix-light">
          <div className="name">{qrInfo?.name}</div>
        </div>
        <div className="img">
          {qrInfo?.payment_portal_image && (
            <img
              src={qrInfo.payment_portal_image.original}
              alt="User Profile"
            />
          )}
        </div>
        <div className="info bg-primary fs-13 fw-bold text-white">
          {buttonTitle[this.props.index]}
        </div>
      </div>
    );
  }
}
