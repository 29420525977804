import React, { Component } from "react";

export default class TipButton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className="tip-button row my--20 justify-content-center">
          <div className="col-auto">
            <a
              href="javascript:void(0)"
              className="text-decoration-none enter-card-details d-flex align-items-center"
              onClick={() => this.props.onClick()}
            >
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect x="2" y="2" width="20" height="20" rx="10" fill="#9600FF" />
                  <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="white" />
                  <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="white" />
                </svg>
              </span>
              {this.props.title}
            </a>
          </div>
        </div>
      </>
    );
  }
}
