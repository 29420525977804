import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import Api from "./constants/Api";
import General from "./constants/General";

import Backend from "./utils/Backend";

import PaymentApp from "./pages/App";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Header from "./pages/components/Header";

import NotFound from "./pages/components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import "./App.css";

import "./assets/scss/app.scss";
import "./assets/css/custom.css";

toast.configure();

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  /** Empty Method **/
  componentDidMount() {}

  _getScript(path) {
    const script = document.createElement("script");

    script.src = path;

    return script;
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i];
      document.body.appendChild(script);
      await this._sleep(500);
    }
  }

  _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/404" component={NotFound} />
          <UnauthenticatedRoute exact path="/:code" component={PaymentApp}/>
          <UnauthenticatedRoute exact path="/qr/:code" component={PaymentApp} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    );
  }
}
