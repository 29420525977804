export default class Currency {

  static format(symbol, value) {
    let dollarValue = value / 100
    let price = symbol + dollarValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return price.replace(".00", "")
  }

  static parseNumber(value, locale = navigator.language) {
    if(!value){
      return 0
    }

    const example = Intl.NumberFormat(locale).format('1.1');
    const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, 'g');
    const cleaned = value.toString().replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');

    return parseFloat(normalized);
  }
}
