import React from "react";

import notFoundImg from "../../assets/images/svg/not-found/empty-street.svg";

import "../../App.css";

import General from "../../utils/General";

const NotFound = () => {
  let isQR = General.getUrlParameter("q") || false
  return (
    <div>
      <div className="not-found notFound-container">
        <div className="shape-img">
          <h2>Xelda</h2>
        </div>
        <h1>{ isQR ? "QR Code Unavailable" : "404"}</h1>
        <h4>{ isQR ? "If you are the owner of this QR Code please ensure your email is verified to process payments" : "We are sorry but the page you are looking for does not exist."}</h4>
        <img className="notFound-img" src={notFoundImg} alt="Empty Street" />
        <a
          className="logo text-decoration-none"
          href={window.General.WebsiteUrl}
          rel="noreferrer"
        >
          <button className="home-button">Home</button>
        </a>
      </div>
    </div>
  );
};

export default NotFound;
